const Loading = () => {
    return (
        <div className="flex items-center justify-center h-screen">
            <div className="relative flex justify-center items-center">
                <div className="w-16 h-16 rounded-full border-4 border-t-4 border-green-600 animate-spin"></div>
                <div className="absolute text-center text-gray-700 font-bold">Loading...</div>
            </div>
        </div>
    );
};

export default Loading;
