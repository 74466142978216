import axios from "axios";
import { useEffect, useState } from "react";
import { Ad } from "./Banner";


function NewsDetailsAds() {
    const [right2, setAds2] = useState<Ad[]>([]);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchAds = async () => {
            try {
                // const res = await axios.get<Ad[]>('http://localhost:8000/api/ads');
                const res = await axios.get<Ad[]>('https://manage.sportski-info.mk/api/ads');
                const right2Ads = res.data.filter(ad => ad.location === 'right2');
                setAds2(right2Ads);
            } catch (error) {
                setError('Грешка')
            }
        };

        fetchAds();
    }, []);

    const getImageSource = (imagePath?: string) => {
        if (!imagePath) {
            // return `http://localhost:8000/uploads/ads/placeholder.jpg`;
            return `https://manage.sportski-info.mk/uploads/ads/placeholder.jpg`;
        }

        const isUrl = (path: string) => /^(http|https):\/\//i.test(path);
        return isUrl(imagePath)
            ? imagePath
            // : `http://localhost:8000/uploads/ads/${imagePath}`;
            : `https://manage.sportski-info.mk/uploads/ads/${imagePath}`;
    };
    return (
        <div className="ad-container lg:hidden flex  w-full my-5 justify-center items-center p-4">
            {right2.map((ad) => (
                <div key={ad.id} className="w-full max-w-xs mx-auto mb-4">
                    <img
                        src={getImageSource(ad.ad_content)}
                        alt={`Ad ${ad.id}`}
                        className="w-full h-40 object-cover"
                    />
                </div>
            ))}
        </div>
    )
}

export default NewsDetailsAds