import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { FaComments } from 'react-icons/fa';
import { Ad } from './Banner';

type Comment = {
    id: number;
    name: string;
    text: string;
    timestamp: Date;
};

interface CommentsProps {
    newsId: number;
}

function Comments({ newsId }: CommentsProps) {
    const [comments, setComments] = useState<Comment[]>([]);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [newComment, setNewComment] = useState('');
    const [ads, setAds] = useState<Ad[]>([]);

    const formatTimeAgo = (timestamp: Date) => {
        const now = new Date().getTime();
        const commentTime = new Date(timestamp).getTime();
        const timeDifference = (now - commentTime) / 1000;

        if (timeDifference < 60) return `${Math.floor(timeDifference)} seconds ago`;
        if (timeDifference < 3600) return `${Math.floor(timeDifference / 60)} minutes ago`;
        if (timeDifference < 86400) return `${Math.floor(timeDifference / 3600)} hours ago`;
        return `${Math.floor(timeDifference / 86400)} days ago`;
    };

    const fetchComments = async () => {
        try {
            // sportski-info.mk
            // const res = await fetch(`http://localhost:8000/api/comments/${newsId}`);
            const res = await fetch(`https://manage.sportski-info.mk/api/comments/${newsId}`);
            const data = await res.json();
            console.log('Fetched comments:', data);
            const formattedComments = data.map((comment: any) => ({
                id: comment.id,
                name: `${comment.first_name} ${comment.last_name}`,
                text: comment.comment,
                timestamp: new Date(comment.created_at),
            }));

            setComments(formattedComments);
        } catch (error) {
            console.error('Грешка при прикажување коментари', error);
        }
    };

    const handleCommentSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (firstName.trim() && lastName.trim() && newComment.trim()) {
            const commentData = {
                first_name: firstName,
                last_name: lastName,
                comment: newComment,
            };

            try {
                // const res = await fetch(`https://localhost:8000/api/comments/${newsId}`, {
                const res = await fetch(`https://manage.sportski-info.mk/api/comments/${newsId}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(commentData),
                });

                if (res.ok) {
                    const result = await res.json();
                    const newComment = {
                        id: result.comment.id,
                        name: `${result.comment.first_name} ${result.comment.last_name}`,
                        text: result.comment.comment,
                        timestamp: new Date(),
                    };

                    setComments((prevComments) => [...prevComments, newComment]);
                    setFirstName('');
                    setLastName('');
                    setNewComment('');
                } else {
                    throw new Error('Грешка при додавање на кометар');
                }
            } catch (error) {
                console.error('Грешка:', error);
            }
        }
    };

    useEffect(() => {
        fetchComments();
    }, [newsId]);

    useEffect(() => {
        const fetchAds = async () => {
            try {
                // const res = await axios.get<Ad[]>('http://localhost:8000/api/ads');
                const res = await axios.get<Ad[]>('https://manage.sportski-info.mk/api/ads');
                const headerAds = res.data.filter(ad => ad.location === 'bottom');
                setAds(headerAds);
            } catch (error) {
                console.error('Грешка:', error);
            }
        };

        fetchAds();
    }, []);

    return (
        <div className='comments my-8'>
            <div className="title mb-4">
                <h2 className='font-bold flex gap-3 items-center'>Коментари<FaComments /></h2>
            </div>
            <div className="container space-y-4 overflow-y-auto max-h-screen">
                {comments.map((comment) => (
                    <div key={comment.id} className="comment bg-gray-100 p-4 rounded-md shadow-sm">
                        <div className="flex items-center space-x-4 mb-2">
                            <div className="avatar w-8 h-8 rounded-full bg-blue-500 text-white flex items-center justify-center">
                                {comment.name[0]}
                            </div>
                            <div>
                                <h4 className="text-sm font-semibold">{comment.name}</h4>
                                <span className="text-xs text-gray-500">
                                    {formatTimeAgo(comment.timestamp)}
                                </span>
                            </div>
                        </div>
                        <div className='max-w-screen-lg'>
                            <p className="text-gray-700 break-words">{comment.text}</p>
                        </div>
                    </div>
                ))}
            </div>
            <form onSubmit={handleCommentSubmit} className="mt-6">
                <div className="lg:flex lg:space-x-4">
                    <input
                        type="text"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        className="w-full p-3 border rounded-md lg:mb-0 mb-4 lg:text-md text-sm"
                        placeholder="Име"
                    />
                    <input
                        type="text"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        className="w-full p-3 border rounded-md lg:text-md text-sm"
                        placeholder="Презиме"
                    />
                </div>
                <textarea
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    className="w-full p-3 border rounded-md mt-4 lg:text-md text-sm"
                    placeholder="Додај коментар..."
                ></textarea>
                <button type="submit" className="mt-2 bg-custom-blue px-4 py-2 rounded-md">
                    <p className='text-white'>Прати</p>
                </button>
            </form>
            <div className="ad-container mt-10">
                {ads.map((ad) => (
                    <div key={ad.id}>
                        <img
                            src={ad.ad_content}
                            alt={`Ad ${ad.id}`}
                            className='w-full object-cover'
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Comments;
