import { FaFacebook, FaInstagram, FaTiktok } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const SocialMedia: React.FC = () => {
    return (
        <div className="w-full ">
            <div className="flex justify-around w-full h-full bg-gray-100 p-4 ">
                <Link to={"https://www.instagram.com/sportski.info/?hl=en"} target='_blank' rel='norefferer'>
                 <div className="text-white text-4xl p-3 rounded-full bg-red-500 hover:bg-red-600">
                    <FaInstagram />
                    </div>
                </Link>
                <Link to={"https://www.facebook.com/Sportskiinfo.makedonija7"} target='_blank' rel='norefferer'>
                <div className="text-white text-4xl p-3 rounded-full bg-blue-600 hover:bg-blue-700">
                    <FaFacebook />
                </div>
                </Link>
                <Link to={"https://www.tiktok.com/@sportski..info"} target='_blank' rel='norefferer'>
                    <div className="text-white text-4xl p-3 rounded-full bg-gray-800 hover:bg-gray-700">
                    <FaTiktok />
                </div>
                </Link>
            </div>
        </div>
    );
};

export default SocialMedia;
