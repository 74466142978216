import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import Banner from './Banner';
import PopularNews from './PopularNews';
import RightContainer from './RightContainer';
import NewsSection from './NewsSection';
import Loading from './Loading';
import NewestNews from './NewestNews';

export interface NewsArticle {
    sport_name: any;
    image: string | undefined;
    id: number;
    title: string;
    content: string;
    sport_id: number;
    image_path: string;
    created_at: string;
    updated_at: string;
    category_id: number;
    user_id: number;
}

export interface Sport {
    id: number;
    name: string;
}

const colorMapping: Record<number, string> = {
    1: 'bg-orange-600',
    2: 'bg-red-600',
    3: 'bg-blue-800',
    4: 'bg-green-600',
    5: 'bg-purple-600',
    8: 'bg-pink-600',
};

const HomePage: React.FC = () => {
    const [news, setNews] = useState<NewsArticle[]>([]);
    const [sports, setSports] = useState<Sport[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [searchInput, setSearchInput] = useState<string>('');
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const searchResultsRef = useRef<HTMLUListElement | null>(null);

    useEffect(() => {
        const fetchNews = async () => {
            try {
                // const res = await axios.get('http://localhost:8000/api/news');
                const res = await axios.get('https://manage.sportski-info.mk/api/news');
                if (res.data.success) {
                    setNews(res.data.data);
                }
            } catch (error) {
                setError('Грешка при прикажување на вестите');
            }
        };

        const fetchSports = async () => {
            try {
                // const res = await axios.get('http://localhost:8000/api/sports');
                const res = await axios.get('https://manage.sportski-info.mk/api/sports');
                setSports(res.data.data);
            } catch (error) {
                setError('Грешка при прикажување на вестите');
            } finally {
                setLoading(false);
            }
        };

        fetchNews();
        fetchSports();
    }, []);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(event.target.value);
        setIsDropdownOpen(event.target.value.length > 0);
    };

    const filteredNews = news.filter((newsItem) =>
        newsItem.title.toLowerCase().includes(searchInput.toLowerCase())
    );

    const handleClickOutside = useCallback((event: MouseEvent) => {
        if (searchResultsRef.current && !searchResultsRef.current.contains(event.target as Node)) {
            setIsDropdownOpen(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    const getImageSource = (imagePath?: string) => {
        if (!imagePath) {
            // return `http://localhost:8000/uploads/images/placeholder.jpg`;
            return `https://manage.sportski-info.mk/uploads/images/placeholder.jpg`;
        }

        const isUrl = (path: string) => /^(http|https):\/\//i.test(path);
        return isUrl(imagePath)
            ? imagePath
            // : `http://localhost:8000/uploads/images/${imagePath}`;
            : `https://manage.sportski-info.mk/uploads/images/${imagePath}`;
    };

    if (loading) return <div>
        <Loading />
    </div>;

    if (error) {
        return <div>
            <p>{error}</p>
        </div>;
    }

    return (
        <div className="wrapper max-w-screen-xl lg:w-4/5 w-full m-auto min-h-screen lg:px-0 lg:py-5 pt-0 pb-3">
            <Banner />
            <div className="flex flex-col lg:flex-row gap-5">
                <div className="container lg:w-9/12 w-full">
                    <div className='search-box mb-5 relative lg:px-0 px-3'>
                        <div className='relative'>
                            <input
                                type='search'
                                placeholder='Пребарувај...'
                                className='border-blue-900 border-b-2 px-10 lg:w-1/2 w-full py-2'
                                onChange={handleSearchChange}
                                value={searchInput}
                            />
                            <FaSearch className='absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400' />
                        </div>
                        {isDropdownOpen && filteredNews.length > 0 && (
                            <ul
                                ref={searchResultsRef}
                                className='absolute bg-white border border-gray-300 rounded-lg w-full lg:w-1/2 mt-2 z-50 max-h-80 overflow-y-auto'
                            >
                                {filteredNews.map((newsItem) => (
                                    <Link to={`/news/${newsItem.id}`} key={newsItem.id}>
                                        <li className='p-2 hover:bg-gray-100 cursor-pointer flex gap-3'>
                                            <img
                                                src={getImageSource(newsItem.image_path)}
                                                alt={newsItem.id.toString()}
                                                width={100}
                                                height={100}
                                                className='w-16 h-16 object-cover rounded-lg' />
                                            <div>
                                                <h3 className='font-semibold'>{newsItem.title}</h3>
                                                <p className='text-sm text-gray-500'>{newsItem.content.slice(0, 60)}...</p>
                                            </div>
                                        </li>
                                    </Link>
                                ))}
                            </ul>
                        )}
                    </div>
                    <PopularNews />
                    <NewestNews />
                    <div className="flex flex-col gap-5">
                        {sports.map((sport) => (
                            <NewsSection
                                key={sport.id}
                                title={sport.name}
                                news={news}
                                sport_id={sport.id}
                                color={colorMapping[sport.id]}
                            />
                        ))}
                    </div>
                </div>
                <RightContainer />
            </div>
        </div>
    );
};

export default HomePage;

