import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NewsArticle } from './Home';
import { Link, useLocation } from 'react-router-dom';
import Loading from './Loading';
import RightContainer from './RightContainer';

const SportPage: React.FC = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const sportId = query.get('sportId');
    const categoryId = query.get('categoryId');

    const [news, setNews] = useState<NewsArticle[]>([]);
    const [sportName, setSportName] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchNews = async () => {
            if (sportId) {
                try {
                    // const response = await axios.get('http://localhost:8000/api/news');
                    const response = await axios.get('https://manage.sportski-info.mk/api/news');
                    const allNews: NewsArticle[] = response.data.data;

                    const filteredNews = allNews.filter((article) => article.sport_id === Number(sportId));
                    if (categoryId) {
                        setNews(filteredNews.filter((article) => article.category_id === Number(categoryId)));
                    } else {
                        setNews(filteredNews);
                    }

                    // const sportResponse = await axios.get(`http://localhost:8000/api/sports/${sportId}`);
                    const sportResponse = await axios.get(`https://manage.sportski-info.mk/api/sports/${sportId}`);
                    setSportName(sportResponse.data.name);
                } catch (error) {
                    console.error('Грешка:', error);
                } finally {
                    setLoading(false)
                }
            }
        };

        fetchNews();
    }, [sportId, categoryId]);

    const truncateText = (text: string, maxLength: number) => {
        return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
    };

    const getImageSource = (imagePath?: string) => {
        // return imagePath ? `http://localhost:8000/uploads/images/${imagePath}` : `http://localhost:8000/uploads/images/placeholder.jpg`;
        return imagePath ? `https://manage.sportski-info.mk/uploads/images/${imagePath}` : `https://manage.sportski-info.mk/uploads/images/placeholder.jpg`;
    };

    if (loading) {
        return <div><Loading /></div>;
    }

    return (
        <div className="wrapper max-w-screen-xl mx-auto min-h-screen py-5 px-4 lg:px-0">
            <h1 className="text-3xl font-bold text-center bg-gray-900 text-white px-4 py-3 uppercase">
                {sportName ? sportName : 'Sport News'}
            </h1>
            <div className="flex flex-col lg:flex-row gap-5 py-4">
                <div className="flex-1">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                        {news.length === 0 ? (
                            <p className="text-center">Не се пронајдени вести за овој спорт/категорија</p>
                        ) : (
                            news.map((article) => (
                                <Link key={article.id} to={`/news/${article.id}`} className="block">
                                    <img
                                        src={getImageSource(article.image_path)}
                                        alt={article.title}
                                        className="w-full h-40 object-cover rounded"
                                    />
                                    <div className="">
                                        <h2 className="mt-2 mb-1 text-md lg:text-lg font-semibold hover:text-red-500 transition-all duration-300">
                                            {article.title}
                                        </h2>
                                        {/* <p className="text-gray-700">{truncateText(article.content, 140)}</p> */}
                                        <div
                                            className="text-gray-700 text-sm"
                                            dangerouslySetInnerHTML={{ __html: truncateText(article.content, 120) }}
                                        />
                                    </div>
                                </Link>
                            ))
                        )}
                    </div>
                </div>
                <RightContainer />
            </div>
        </div>
    );
};

export default SportPage;
