import React from 'react';
import { Link } from 'react-router-dom';

export interface NewsArticle {
  id: number;
  title: string;
  content: string;
  image_path?: string;
  sport_name?: string;
  sport_id: number;
}

interface NewsSectionProps {
  title: string;
  news: NewsArticle[];
  sport_id: number;
  color: string;
}

const truncateText = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  }
  return text;
};

const getImageSource = (imagePath?: string) => {
  if (!imagePath) {
    // return `http://localhost:8000/uploads/images/placeholder.jpg`; 
    return `https://manage.sportski-info.mk/uploads/images/placeholder.jpg`;
  }

  const isUrl = (path: string) => /^(http|https):\/\//i.test(path);
  return isUrl(imagePath)
    ? imagePath
    // : `http://localhost:8000/uploads/images/${imagePath}`;
    : `https://manage.sportski-info.mk/uploads/images/${imagePath}`;
};

const NewsSection: React.FC<NewsSectionProps> = ({ title, news, sport_id, color }) => {
  const filteredNews = news.filter((newsItem) => newsItem.sport_id === sport_id);

  return (
    <section className={`${title.toLowerCase()}-section`} id={title.toLowerCase()}>
      <div className={`title text-2xl ${color} text-white px-3 py-3 uppercase lg:w-1/2 rounded-tr-full`}>
        {title}
      </div>
      <div className='py-1.5 bg-gray-600 w-full'></div>
      <div className="bg-gray-100 p-4 flex flex-col lg:flex-row gap-8">
        <div className='w-full lg:w-1/2'>
          {filteredNews.slice(0, 1).map(newsItem => (
            <div key={newsItem.id} className="news-item mb-5">
              <div className="news-image mb-4">
                <img
                  src={getImageSource(newsItem.image_path)}
                  alt={newsItem.title}
                  width={100}
                  height={100}
                  className="object-cover h-80 w-full rounded-lg"
                />
              </div>
              <Link to={`/news/${newsItem.id}`}>
                <h3 className='font-bold cursor-pointer hover:text-red-500 transition-all duration-300 text-md lg:text-xl mb-3'>
                  {newsItem.title}
                </h3>
              </Link>
              <p>
                {truncateText(newsItem.content, 80)}
              </p>
            </div>
          ))}
        </div>
        <div className='w-full lg:w-1/2'>
          {filteredNews.slice(1).map(newsItem => (
            <div key={newsItem.id} className="news-item mb-5 flex">
              <div className="news-image w-24 h-24 flex-shrink-0">
                <img
                  src={getImageSource(newsItem.image_path)}
                  alt={newsItem.title}
                  width={100}
                  height={100}
                  className="object-cover h-full w-full rounded-lg"
                />
              </div>
              <div className="news-body flex-grow ms-3">
                <Link to={`/news/${newsItem.id}`}>
                  <h3 className='font-bold cursor-pointer text-md lg:text-lg hover:text-red-500 transition-all duration-300'>
                    {newsItem.title}
                  </h3>
                </Link>
                <div
                  className="text-sm leading-relaxed"
                  dangerouslySetInnerHTML={{ __html: truncateText(newsItem.content, 80) }}
                />
                {/* <p>{truncateText(newsItem.content, 80)}</p> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default NewsSection;
