import { useEffect, useState } from "react";
import { NewsArticle } from "./Home";
import axios from "axios";

function PopularNews() {
    const [newsData, setNewsData] = useState<NewsArticle[]>([]);

    useEffect(() => {
        const fetchNewsData = async () => {
            // const res = await axios.get('http://localhost:8000/api/news');
            const res = await axios.get('https://manage.sportski-info.mk/api/news');
            setNewsData(res.data.data || []);
        };
        fetchNewsData();
    }, []);

    const getImageSource = (imagePath?: string) => {
        if (!imagePath) {
            // return `http://localhost:8000/uploads/images/placeholder.jpg`;
            return `https://manage.sportski-info.mk/uploads/images/placeholder.jpg`;
        }

        const isUrl = (path: string) => /^(http|https):\/\//i.test(path);
        return isUrl(imagePath)
            ? imagePath
            // : `http://localhost:8000/uploads/images/${imagePath}`;
            : `https://manage.sportski-info.mk/uploads/images/${imagePath}`;
    };

    return (
        <div className='w-full my-5 bg-gray-100'>
            <div className='title p-3 text-center w-full'>
                <h2 className='lg:text-xl text-2xl lg:uppercase font-bold flex lg:justify-start justify-center items-center gap-2'>Популарни вести</h2>
                <div className='mt-1 bg-red-600 m-auto lg:w-full w-16 lg:h-2 h-0.5'></div>
            </div>
            <div className="container overflow-x-auto flex space-x-4 py-2 px-4">
                {newsData.map((newsItem) => (
                    <div key={newsItem.id} className="news-item flex-shrink-0 w-80 relative">
                        <a href={`/news/${newsItem.id}`} className="block relative overflow-hidden">
                            <img
                                src={getImageSource(newsItem.image_path)}
                                alt={newsItem.id.toString()}
                                width={100}
                                height={100}
                                className="object-cover rounded-lg w-full h-48 transition-transform duration-300 ease-in-out transform hover:scale-110" // Zoom effect on hover
                            />
                            <div className="absolute bottom-0 left-0 w-full h-full bg-gradient-to-t from-black to-transparent rounded-lg"></div>
                            <h3 className="font-semibold lg:text-lg text-sm leading-tight hover:text-red-500 duration-300 text-white absolute bottom-3 left-3 z-10">
                                {newsItem.title}
                            </h3>
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default PopularNews;
