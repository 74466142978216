import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaRegClock } from 'react-icons/fa';
import { NewsArticle } from './Home';
import SocialMedia from './SocialMedia';
import { Ad } from './Banner';
import axios from 'axios';
import Loading from './Loading';

const RightContainer: React.FC = () => {
    const [newsData, setNewsData] = useState<NewsArticle[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [right1, setAds] = useState<Ad[]>([]);
    const [right2, setAds2] = useState<Ad[]>([]);
    const [right1Index, setRight1Index] = useState(0);
    const [right2Index, setRight2Index] = useState(0);

    useEffect(() => {
        const fetchNewsData = async () => {
            try {
                // const res = await fetch('http://localhost:8000/api/news');
                const res = await fetch('https://manage.sportski-info.mk/api/news');
                const data = await res.json();

                const twentyFourHoursAgo = Date.now() - 24 * 60 * 60 * 1000;
                const filteredNews = data.data.filter((item: NewsArticle) => {
                    return new Date(item.created_at).getTime() >= twentyFourHoursAgo;
                });

                setNewsData(filteredNews);
            } catch (error) {
                console.error('Грешка:', error);
            } finally {
                setLoading(false)
            }
        };

        fetchNewsData();
    }, []);

    useEffect(() => {
        const fetchAds = async () => {
            try {
                // const res = await axios.get<Ad[]>('http://localhost:8000/api/ads');
                const res = await axios.get<Ad[]>('https://manage.sportski-info.mk/api/ads');
                const right1Ads = res.data.filter(ad => ad.location === 'right1');
                const right2Ads = res.data.filter(ad => ad.location === 'right2');
                setAds(right1Ads);
                setAds2(right2Ads);
            } catch (error) {
                setError('Грешка');
            }
        };

        fetchAds();
    }, []);

    useEffect(() => {
        if (right1.length > 1) {
            const interval = setInterval(() => {
                setRight1Index((prevIndex) => (prevIndex + 1) % right1.length);
            }, 2000);
            return () => clearInterval(interval);
        }
    }, [right1]);

    useEffect(() => {
        if (right2.length > 1) {
            const interval = setInterval(() => {
                setRight2Index((prevIndex) => (prevIndex + 1) % right2.length);
            }, 3000);
            return () => clearInterval(interval);
        }
    }, [right2]);

    const getImageSource = (imagePath?: string) => {
        if (!imagePath) {
            // return `http://localhost:8000/uploads/ads/placeholder.jpg`; 
            return `https://manage.sportski-info.mk/uploads/ads/placeholder.jpg`;
        }

        const isUrl = (path: string) => /^(http|https):\/\//i.test(path);
        return isUrl(imagePath)
            ? imagePath
            // : `http://localhost:8000/uploads/ads/${imagePath}`;
            : `https://manage.sportski-info.mk/uploads/ads/${imagePath}`;
    };

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <div className="right-container w-full lg:w-1/4 flex-col hidden lg:flex">
            <SocialMedia />
            <div className="ad-container bg-gray-100 w-full h-96 my-5 flex justify-center items-center shadow-lg">
                {right1.length > 0 && (
                    <div>
                        <a href={right1[right1Index].link} target="_blank" rel="noopener noreferrer">
                            <img
                                src={getImageSource(right1[right1Index].ad_content)}
                                alt={`Ad ${right1[right1Index].id}`}
                                className='w-full object-cover'
                            />
                        </a>
                    </div>
                )}
            </div>

            <div className='p-2 bg-gray-100 shadow-lg'>
                <div className='uppercase font-bold'>
                    <h2 className='text-xl flex items-center gap-2'><FaRegClock />Најнови вести</h2>
                </div>
                <div className='bg-red-600 py-1'></div>
            </div>

            <div className="container bg-gray-100 w-full shadow-lg h-screen overflow-y-auto px-2 pt-2">
                {newsData.map((item) => (
                    <div key={item.id} className='news-item mb-6 relative'>
                        <img
                            src={getImageSource(item.image_path)}
                            alt={item.id.toString()}
                            width={100}
                            height={100}
                            className='w-full h-44 object-cover rounded-lg' />

                        <div className="absolute bottom-0 left-0 w-full h-full bg-gradient-to-t from-black to-transparent rounded-lg flex items-end p-4">
                            <Link to={`/news/${item.id}`}>
                                <h3 className='text-white text-lg cursor-pointer hover:text-red-500 transition-all duration-300'>
                                    {item.title}
                                </h3>
                            </Link>
                        </div>
                    </div>
                ))}
            </div>

            <div className="ad-container bg-gray-100  w-full h-96 my-5 flex justify-center items-center shadow-lg">
                {right2.length > 0 && (
                    <div>
                        <a href={right2[right2Index].link} target="_blank" rel="noopener noreferrer">
                            <img
                                src={getImageSource(right2[right2Index].ad_content)}
                                alt={`Ad ${right2[right2Index].id}`}
                                className='w-full object-cover'
                            />
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
};

export default RightContainer;
